/* global ajaxObj */
import './scripts/dynamic-adaptive';
import './scripts/footer';
import './scripts/blog';
import 'jquery-match-height';
import './scripts/header';
import './scripts/module-popup-cards';
//import './scripts/foundation';
import 'slick-carousel';
import Foundation from './scripts/foundation';
import './scripts/slick-custom-dots';

$(document).foundation();

/* ---------------------------------------
	Overlay Newsletter
--------------------------------------- */
let $jsOverlay = $('.js-overlay');
let alreadyShow = sessionStorage.getItem('alreadyShow');
if (alreadyShow !== 'true') {
  setTimeout(function () {
    $jsOverlay.fadeIn();
    $('.single-product').addClass('scroll-fixed-popup');
    sessionStorage.setItem('alreadyShow', 'true');
  }, 10000);
}
$(document).mouseup(function (e) {
  let overlay = $('.overlay-row');
  if (e.target !== overlay[0] && overlay.has(e.target).length === 0) {
    hideOverlay();
  }
});
$('.js-button-close').on('click', function () {
  hideOverlay();
  $('.single-product').removeClass('scroll-fixed-popup');
});
function hideOverlay() {
  $jsOverlay.fadeOut();
  $('.single-product').removeClass('scroll-fixed-popup');
}

$('.js-text-read-more').on('click', function () {
  const textToShow = $(this).data('text-show');
  const textToHide = $(this).data('text-hide');
  $(this).toggleClass(function () {
    if ($(this).hasClass('open')) {
      $(this).html(textToShow);
      $(this).prev('.text-read-more').removeClass('open');
    } else {
      $(this).html(textToHide);
      $(this).prev('.text-read-more').addClass('open');
    }

    return 'open';
  });
});

$('.product-item .overline').matchHeight();

(function () {
  const activeTerms = [];
  const chips = $('.products-chip');
  chips.on('click', function () {
    const termId = $(this).attr('data-term-id');
    const taxonomy = $(this).attr('data-taxonomy');
    const category = $(this).attr('data-current-category');
    if (termId) {
      const termIdx = activeTerms.indexOf(termId);
      if (termIdx === -1) {
        activeTerms.push(termId);
      } else {
        activeTerms.splice(termIdx, 1);
      }
    }

    $.ajax({
      url: ajaxObj.ajaxUrl,
      type: 'GET',
      data: {
        action: 'get_products',
        terms: activeTerms,
        taxonomy,
        category,
      },
      success: function (resp) {
        $('.products-grid').html(resp.html);
        chips.removeClass('active');
        activeTerms.forEach((term) => {
          $(`.products-chip[data-term-id=${term}]`).addClass('active');
        });
      },
    });
  });
})();

$('.js-card-overline').matchHeight();
$(window).on('load changed.zf.mediaquery', function () {
  if (Foundation.MediaQuery.is('large')) {
    $('.jobs .job-card').matchHeight({
      byRow: false,
    });
  } else {
    $('.jobs .job-card').matchHeight({
      byRow: true,
    });
  }
});

$(window).on('load resize', function () {
  const sections = $('section.path');
  if (sections.length === 0) return;

  sections.each(function (_, section) {
    const row = $(section).find('.row');
    $(section).find('.breakpoint__title').matchHeight({ byRow: false });

    row.each(function (_, item) {
      const icons = $(item).find('.breakpoint__icon');
      const title = $(item).find('.breakpoint__title');
      $(item).css(
        '--height',
        `${Math.round(icons.height()) + Math.round(title.height())}px`
      );
    });
  });
});

$('.services .repeater__title').matchHeight();

$('.js-image-slider').each((idx, item) => {
  const arrowPrev = $(item).siblings('.slider-pagination').find('.slick-prev');
  const arrowNext = $(item).siblings('.slider-pagination').find('.slick-next');
  const sliderDots = $(item).siblings('.slider-pagination').find('.pagination-dots');
  $(item).slick({
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    rows: 0,
    dots: true,
    fade: true,
    prevArrow: arrowPrev,
    nextArrow: arrowNext,
    appendDots: sliderDots,
  });
});

/* ---------------------------------------
    Scroll
--------------------------------------- */

$('a[href*="#"]').on('click', function (e) {
  const $this = $(this);
  if ($this.closest('[data-tabs]').length || $this.closest('[data-accordion]').length)
    return;

  const href = $this.attr('href');
  if (href.indexOf('#') === 0) {
    e.preventDefault();
    scrollToBlock(href);
  } else {
    let split = href.split('#');
    let location = window.location.href.replace('#', '');
    if (location === split[0]) {
      e.preventDefault();
      scrollToBlock('#' + split[1]);
    }
  }
});

//Smooth scroll to another page
let locationHash = window.location.hash;
if (locationHash) {
  window.location.hash = '';
  scrollToBlock(locationHash);
}

function scrollToBlock(id) {
  const block = $(id);
  if (!block.length) return;
  const headerHeight = $('.header').height();
  let offset = headerHeight;
  console.log(offset);

  $('html,body').animate(
    {
      scrollTop: $(id).offset().top - offset,
    },
    400
  );
}

$('.tab-item').click(function () {
  let self = this;
  setTimeout(function () {
    let theOffset = $(self).offset();
    $('body,html').animate({ scrollTop: theOffset.top - 100 });
  }, 310);
});
